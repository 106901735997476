import React from "react";
import ClientOnly from "./src/clientOnly.js";

export default function MuiRootWrapper({ element }) {
  try {
    return <ClientOnly>{element}</ClientOnly>;
  } catch (e) {
    return <ClientOnly>{element}</ClientOnly>;
  }
}
